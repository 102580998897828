<template>
  <div class="citizen-charter-wrapper">
    <div class="mb-48 component__header">
      <h1>नागरिक वडापत्र</h1>
    </div>
    <div class="container">
      <div
        class="main-container citizen-charter-detail-wrapper"
        v-if="Object.keys(details).length"
      >
        <div class="flex flex-pdf">
          <span
            class="mb-24 back-button"
            @click="$router.push(`/citizen-charter/`)"
          >
            नागरिक वडापत्र फिर्ता जानुहोस्
          </span>
          <button
            @click="$store.dispatch('windowPrint')"
            class="btn download-pdf"
          >
            PDF डाउनलोड गर्नुहोस्
          </button>
        </div>
        <h2>{{ details.name }}</h2>
        <div class="mb-24 card card-plan">
          <h3 class="mb-24">योजना सम्झौता</h3>
          <dl class="flex">
            <dt class="service-charge">सेवा शुल्क</dt>
            <dd>{{ details.service.service_charge || "-" }}</dd>
            <dt class="service-duration">लाग्ने समय</dt>
            <dd>{{ details.service.service_duration || "-" }}</dd>
            <dt class="service-provider">सेवा प्रदान गर्ने कार्यालय</dt>
            <dd>{{ details.service.service_provider.label || "-" }}</dd>
            <dt class="responsible-branch">जिम्मेवार शाखा</dt>
            <dd>{{ details.responsible.responsible_branch || "-" }}</dd>
            <dt class="responsible-room-number">कोठा नं</dt>
            <dd>{{ details.responsible.room_no || "-" }}</dd>
            <dt class="responsible-contact-number">सम्पर्क नं</dt>
            <dd>
              <p
                v-for="(ele, index) in splitString(
                  details.responsible.contact_number
                )"
                :key="index"
              >
                {{ ele }}
              </p>
            </dd>
            <dt class="service-grievance">
              सेवा सम्बन्धित गुनासो सुन्ने जिम्मेवार अधिकारी
            </dt>
            <dd>{{ details.responsible.responsible_official || "-" }}</dd>
            <dt class="service-grievance-listener">
              सेवा सम्बन्धित गुनासो सुन्ने प्रक्रिया
            </dt>
            <dd>
              <p
                v-for="(ele, index) in splitString(
                  details.service.service_grievance_official
                )"
                :key="index"
              >
                {{ ele }}
              </p>
            </dd>
            <dt class="service-form-link">
              सम्बन्धित सेवा प्राप्त गर्न भर्नु पर्ने फारम
            </dt>
            <dd>{{ details.service.service_form_link || "-" }}</dd>
          </dl>
        </div>
        <div class="">
          <div class="mb-24 card">
            <h3 class="mb-24">
              प्रक्रिया / सेवा सुबिधा प्राप्त गर्न आवश्यक पर्ने प्रक्रियाहरु
            </h3>
            <ol v-if="details.service.service_procedure">
              <li
                v-for="(ele, index) in details.service.service_procedure.split(
                  ','
                )"
                :key="index"
              >
                {{ `${englishToNepaliNumber(index + 1)}. ` }}{{ ele }}
              </li>
            </ol>
          </div>
          <div class="mb-24 card">
            <h3 class="mb-24">आवश्यक पर्ने कागजातहरु</h3>
            <ol v-if="details.responsible.required_documents">
              <li
                v-for="(
                  ele, index
                ) in details.responsible.required_documents.split(',')"
                :key="index"
              >
                {{ `${englishToNepaliNumber(index + 1)}. ` }}{{ ele }}
              </li>
            </ol>
          </div>
          <div class="mb-24 card" v-if="details.service_audio">
            <h3 class="mb-24">नागरिक वडापत्र अडियो</h3>
            <div class="flex flex-audio" v-if="details.service_audio.length">
              <div v-for="(audio, i) in details.service_audio" :key="i">
                <audio controls>
                  <source :src="audio.audio_file" type="audio/webm" />
                  Your browser does not support the audio tag.
                </audio>
                <p><strong>अडियो भाषा:</strong> {{ audio.language }}</p>
              </div>
            </div>
            <NoDataFound v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
  <PdfDownload ref="pdf">
    <template v-slot:content>
      <div>
        <h1 class="mb-16">नागरिक वडापत्र</h1>
        <h2 class="mb-16">{{ details.name }}</h2>
        <div class="mb-24">
          <div v-if="Object.keys(details).length">
            <table>
              <tbody>
                <tr>
                  <td class="service-charge">सेवा शुल्क</td>
                  <td>{{ details.service.service_charge }}</td>
                </tr>
                <tr>
                  <td class="service-duration">लाग्ने समय</td>
                  <td>{{ details.service.service_duration }}</td>
                </tr>
                <tr>
                  <td class="service-provider">सेवा प्रदान गर्ने कार्यालय</td>
                  <td>{{ details.service.service_provider.label }}</td>
                </tr>
                <tr>
                  <td class="responsible-branch">जिम्मेवार शाखा</td>
                  <td>{{ details.responsible.responsible_branch }}</td>
                </tr>
                <tr>
                  <td class="responsible-room-number">कोठा नं</td>
                  <td>{{ details.responsible.room_no }}</td>
                </tr>
                <tr>
                  <td class="responsible-contact-number">सम्पर्क नं</td>
                  <td>{{ details.responsible.contact_number }}</td>
                </tr>
                <tr>
                  <td class="service-grievance">
                    सेवा सम्बन्धित गुनासो सुन्ने जिम्मेवार अधिकारी
                  </td>
                  <td>{{ details.responsible.responsible_official }}</td>
                </tr>
                <tr>
                  <td class="service-grievance-listener">
                    सेवा सम्बन्धित गुनासो सुन्ने प्रक्रिया
                  </td>
                  <td>{{ details.service.service_grievance_official }}</td>
                </tr>
                <tr>
                  <td class="service-form-link">
                    सम्बन्धित सेवा प्राप्त गर्न भर्नु पर्ने फारम
                  </td>
                  <td>{{ details.service.service_form_link }}</td>
                </tr>
                <tr>
                  <td>
                    प्रक्रिया / सेवा सुबिधा प्राप्त गर्न आवश्यक पर्ने
                    प्रक्रियाहरु
                  </td>
                  <td>{{ details.service.service_procedure }}</td>
                </tr>
                <tr>
                  <td>आवश्यक पर्ने कागजातहरु</td>
                  <td>{{ details.responsible.required_documents }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </PdfDownload>
</template>

<script>
import LocalGovernmentRepo from "../../repositories/LocalGovernmentRepository";
import { englishToNepaliNumber } from "nepali-number";
import PdfDownload from "./../../components/shared/PdfDownload.vue";
import { defineAsyncComponent } from "vue";
const NoDataFound = defineAsyncComponent(() =>
  import("../../components/NoDataFound.vue")
);
export default {
  data() {
    return {
      id: null,
      details: {},
      englishToNepaliNumber,
    };
  },
  components: {
    NoDataFound,
    PdfDownload,
  },
  async mounted() {
    try {
      this.id = this.$route.params.id;
      let { data } = await LocalGovernmentRepo.getServiceDetails(this.id);
      this.details = data.data;
    } catch (error) {
      console.error(error);
    }

    this.$store.dispatch("setLoading", false);
  },
  methods: {
    splitString(str) {
      if (str) {
        return str.split(",");
      }

      return [];
    },
  },
};
</script>

<style lang="scss">
.card-plan dl dt {
  padding-left: 32px;
  position: relative;
  &:before {
    background-image: url("../../assets/ic_sprite.svg");
    background-repeat: no-repeat;
    content: "";
    height: 18px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 18px;
  }
  &.service-charge:before {
    background-position: -66px 0;
  }
  &.service-duration:before {
    background-position: -66px -47px;
  }
  &.service-provider:before {
    background-position: -66px -91px;
  }
  &.responsible-branch:before {
    background-position: -66px -136px;
  }
  &.responsible-room-number:before {
    background-position: -66px -181px;
  }
  &.responsible-contact-number:before {
    background-position: -66px -225px;
  }
  &.service-grievance:before {
    background-position: -66px -274px;
  }
  &.service-grievance-listener:before {
    background-position: -66px -325px;
  }
  &.service-form-link:before {
    background-position: -66px -371px;
  }
}
</style>
